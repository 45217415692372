import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/node_modules/@apollo/experimental-nextjs-app-support/dist/ssr/ApolloNextAppProvider.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/node_modules/@apollo/experimental-nextjs-app-support/dist/ssr/hooks.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/atoms/StripDataMoveable/StripDataMoveable.module.scss");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/common/Analytics/CustomButton.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/molecules/Carousels/Carousel.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/molecules/Carousels/CarouselHome.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/molecules/Carousels/HeroCarousel/ClientSideButton.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/molecules/Carousels/HeroCarousel/ClientWrapper.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/molecules/StripData/StripData.module.scss");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/molecules/Carousels/HeroCarousel/HeroCarousel.module.scss");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/components/sections/BlogList/index.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/revamp/LazyLoadSection.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/revamp/molecules/PageCard/index.tsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/revamp/sections/BannerCtaWithDesc/style.module.scss");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/revamp/sections/PageCards/PagesCards.module.scss");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/revamp/sections/AccreditionsRecognitions/AccreditionsRecognitions.module.scss");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/revamp/molecules/SectionCard/SectionCard.module.scss");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/revamp/organisms/AccreditionsItems/AccreditionsRecognitions.module.scss");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend/src/revamp/sections/Blogs/styles.module.scss")