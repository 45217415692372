"use client";

import { Swiper, SwiperSlide } from "swiper/react";
import { SwiperOptions } from "swiper/types";
import { Pagination, Autoplay, Grid, Navigation } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/grid";
import "swiper/css";
import "swiper/css/navigation";

export default function Carousel({
  slides,
  config,
  className,
  isDesktop
}: {
  slides: React.ReactNode[];
  config: SwiperOptions;
  className?: string;
  isDesktop?:boolean
}) {
  return (
    <Swiper
      {...config}
      className={className}
      modules={[Pagination, Autoplay, Grid, Navigation]}
      navigation={true}
      loop={true}
      {...isDesktop && {loop:true}}
      id="Carousel"
      
    >
      {slides?.map((slideContent, index) => (
        <SwiperSlide key={index}>{slideContent}</SwiperSlide>
      ))}
    </Swiper>
  );
}
