"use client";

import { Swiper, SwiperSlide } from "swiper/react";
import { SwiperOptions } from "swiper/types";
import { Pagination, Autoplay, Grid } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/grid";
import "swiper/css";

export default function Carousel({
  slides,
  config,
  className,
}: {
  slides: React.ReactNode[];
  config: SwiperOptions;
  className?: string;
}) {
  return (
    <Swiper
      {...config}
      className={`${className} Carousel__blueBullet Carousel__hAuto`}
      modules={[Pagination, Autoplay, Grid]}
      id="Carousel"
      pagination={config?.pagination ? { clickable: true } : false}
    >
      {slides?.map((slideContent, index) => (
        <SwiperSlide key={index}>{slideContent}</SwiperSlide>
      ))}
    </Swiper>
  );
}
