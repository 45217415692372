"use client";

import { getGlobalStateValue } from "@/context/utils";
import { pushToDataLayer } from "@/helpers/gtag";
import styles from "./HeroCarousel.module.scss";

const ClientWrapper = (props) => {
  const onClickHandler = () => {
    pushToDataLayer({
      event: "banner_course_interaction",
      cta_text: props.Program,
      section_name: "Hero Banner",
      header_title: props.Titles?.replace(/<\/?[^>]+(>|$)/g, "") || "NA",
      page_type: getGlobalStateValue("pageType"),
    });
  };

  return (
    <div
    style={
      props.isSecondBanner && !props.isDesktop
        ? { width: props.isLastItem ? styles.lastItem  : "5.6rem" }
        : {}
    }
    className={`banner-course-item ${props.isLastItem ? styles.lastItem : ""}`} key={props.href}>
      <a onClick={onClickHandler} href={props.href}>
        <span className={styles.program}>{props.Program}</span>
        <p style={
      props.isSecondBanner && !props.isDesktop
        ? { fontSize:'8px'}
        : {}
    }>{props.ProgramName}</p>
      </a>
    </div>
  );
};

export default ClientWrapper;
