"use client"
import styles from "./PageCard.module.scss";
import cn from "clsx";
import Markdown from "@/components/atoms/Markdown";
import strapiSingleAttribute from "@/api/strapiSingleAttribute";
import { useEffect, useState } from "react";
import { useRouter } from "next/navigation";

export default function PageCard({
  BGColor,
  TextColor,
  Icon,
  Title,
  Content,
  hasBorder = false,
  imageLoadingStrategy = "eager",
  isOutline,
  fontSizeTitle,
  fontSizeContent,
  fontSizeDescription,
  titleDescription,
  fliptext="",
  cardLength=0,
  isMobile,
  index,
  isH1home=false,
}: any) {
  if (!Title?.enabled && !Content?.enabled && !titleDescription?.enabled) {
    return null;
  }
  const router =useRouter();
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => 
        (prevIndex + 1) % cardLength
      );
    }, 2500);

    return () => clearInterval(interval);
  }, [cardLength]);
  const icon = strapiSingleAttribute(Icon?.source);
  const styleVars: any = isOutline
    ? {
        backgroundColor: "#ffffff",
        color: TextColor,
        borderColor: BGColor,
      }
    : {
        backgroundColor: BGColor,
        color: TextColor,
      };

  const AnnouncementItemStyles = {
    borderColor  : BGColor
  }

  return (
    <div key={index} className={index === 0 ? 'cursor-pointer' : styles.announcementCard}
    {...(index === 0 && isH1home && { onClick: () => router.push('/scholarship') })} >
      <div
         key={index}
          className={index === 0 ? 'cursor-pointer' :cn(styles.announcementInner, {
          [styles.border]: hasBorder,
          [styles.outline]: isOutline,
          [styles.flip] : (isMobile && currentIndex === index)
        })}
      >
        {/* Front Side */}
        <div className={styles.announcementItem} style={AnnouncementItemStyles}>
          {Title?.enabled && (
            <div className={styles.announcementTitle}>
              <Markdown
                className={cn(styles.title, {
                  [styles.titleWithSubtitle]: Icon?.source,
                })}
                style={{ fontSize: fontSizeTitle }}
                sanitize={false}
              >
                {Title?.text}
              </Markdown>
            </div>
          )}

          {titleDescription?.enabled && (
            <div className={styles.announcementSubtitle}>
              <Markdown
                as="p"
                className={styles.titleDescription}
                style={{ fontSize: fontSizeDescription }}
                sanitize={false}
              >
                {titleDescription?.text}
              </Markdown>
            </div>
          )}

          {Content?.enabled && (
            <div className={styles.contentParent}>
              <Markdown
                className={styles.content}
                style={{ fontSize: fontSizeContent }}
                sanitize={false}
              >
                {Content?.text}
              </Markdown>
            </div>

          )}
        </div>

        {/* Back Side */}
        <div className={styles.announcementCardBack} style={styleVars}>
            <Markdown 
              className={styles.underDesc}
              sanitize={false}
            >
              {fliptext}
            </Markdown>
        </div>
        {icon &&
          <img
            src={icon?.url}
            alt={icon?.alternativeText || "Page Card"}
            loading={imageLoadingStrategy}
          />
        }
      </div>
    </div>
  );
}

