"use client"

import { useEffect, useState, useRef } from "react";

const ShowImageOnViewPort = (props) => {

  const [isIntersecting, setIntersecting] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      entry.isIntersecting ? setIntersecting(entry.isIntersecting) : null
    );

    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  return(<div ref={ref} style={{height:"100%"}}>{isIntersecting ? <img { ...props }/> : null}</div>);
};

export default ShowImageOnViewPort;
